<template>
  <div class="fade-animation">
    <h2
      class="fade-animation__text"
      :key="remountText"
      v-html="selectedText"
      ref="animation-text"
      :style="'animation-duration:' + duration + 'ms'"
    ></h2>
  </div>
</template>

<script>
export default {
  name: "FadeAnimationTextArray",
  props: {
    textArray: Array,
    duration: {
      type: Number,
      default: 4500,
    },
  },
  data() {
    return {
      selectedText: "",
      remountText: 0,
      textTimeoutVariable: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.nextIndexTextArray(0);
    });
  },
  beforeDestroy() {
    if (this.textTimeoutVariable) {
      clearTimeout(this.textTimeoutVariable);
    }
  },
  methods: {
    nextIndexTextArray(index) {
      this.selectedText = this.textArray[index];
      this.remountText += 1;
      this.textTimeoutVariable = window.setTimeout(
        this.nextIndexTextArray.bind(undefined, (index + 1) % this.textArray.length),
        this.duration
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-animation {
  margin: 5px 0;
  height: 50px;
  &__text {
    animation-name: fadeinout;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    // animation-duration: 4500ms;
    color: $color-primary-company;
    font-size: 16px;
    font-weight: bold;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  15%,
  80% {
    opacity: 1;
  }
}
</style>
