<template>
  <div class="conveyance">
    <div class="conveyance__title">
      <SectionTitle
        :mandatory="true"
        :title="$t('myInsurance.conveyance.conveyance')"
        :popoverData="popoverData"
      ></SectionTitle>
    </div>
    <div class="conveyance__content">
      <ConveyanceComponent />
    </div>
  </div>
</template>
<script>
import ConveyanceComponent from "../../components/ConveyanceComponent.vue";
import SectionTitle from "../../components/SectionTitle.vue";

export default {
  name: "conveyanceInsuranceComponent",
  components: {
    ConveyanceComponent,
    SectionTitle,
  },

  data() {
    return {
      popoverData: {},
    };
  },
  created() {
    this.popoverData = {
      isPopover: true,
      title: "Important!",
      content: "If multimodal, please choose the International transit as this is considered the mainly transport.",
      target: "conveyanceInsuranceComponent",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.conveyance {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &__content {
    display: flex;
    flex-direction: column;
    padding-right: 2%;
  }
}

@media (max-width: 500px) {
  .conveyance {
    &__content {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
}
</style>
