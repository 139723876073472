<template>
  <div class="location-selector">
    <div class="location-selector__country">
      <span class="location-selector__subtitle">Country</span>
      <Dropdown
        v-model="selectedCountry"
        :options="countriesFilterList"
        :filter="true"
        :showClear="false"
        optionLabel="name"
        class="dropdown"
        @change="selectCountry($event.value)"
      >
        <template #value="slotProps">
          <div class="country-item country-item-value" v-if="slotProps.value">
            <div>{{ slotProps.value.iso3 }}</div>
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
        <template #option="slotProps">
          <div class="country-item">
            <div>{{ slotProps.option.iso3 }}</div>
          </div>
        </template>
      </Dropdown>
    </div>
    <div class="location-selector__location">
      <span class="location-selector__subtitle">City/State/Zip Code</span>
      <AutoComplete
        :label="label"
        :suggestions="filteredStops"
        :requestLength="3"
        :forceSelection="true"
        :requiredField="true"
        :missingField="emptyFields.location"
        :loading="loadingAutoComplete"
        :hideLabel="true"
        groupKey="sectionName"
        @search="getAllLocations($event)"
        @selectOption="selectLocations($event)"
        @blur="resetLocations($event)"
        v-model="locationData"
      >
        <template #icon>
          <b-icon icon="geo-alt" class="shipping-icon--delivery"></b-icon>
        </template>
      </AutoComplete>
    </div>
  </div>
</template>
<script>
import BuildLocationsQuery from "@/utils/LocationsQuery";
import Dropdown from "primevue/dropdown";
import AutoComplete from "@/components/AutoComplete.vue";
import getCountriesList from "@/utils/countriesList";
import { mapGetters } from "vuex";

export default {
  /* eslint-disable-line max-len */
  name: "LocationSelector",
  components: {
    Dropdown,
    AutoComplete,
  },
  props: {
    title: {
      type: String,
      default: "title",
    },
    label: {
      type: String,
      default: "label",
    },
  },
  data() {
    return {
      emptyFields: {
        location: false,
      },
      selectedCountry: "US",
      locationData: null,
      countries: [],
      countriesFilterList: [],
      filteredStops: [{ sectionName: "Zip Codes", options: [] }],
      listExcludedCountries: [
        "Afghanistan",
        "Angola",
        "Belarus",
        "Congo/DR Congo",
        "Democratic Republic of the Congo",
        "Republic of the Congo",
        "Ivory Coast",
        "Myanmar (Burma)",
        "Cote dIvoire (Ivory Coast)",
        "Cuba",
        "Eritrea",
        "Ethiopia",
        "Iran",
        "Iraq",
        "Liberia",
        "Libya",
        "Myanmar",
        "Nigeria",
        "North Korea",
        "Rwanda",
        "Sierra Leone",
        "Somalia",
        "Sudan",
        "South Sudan",
        "Syria",
        "Uganda",
        "Yemen",
        "Zimbabwe",
      ],
      loadingAutoComplete: false,
    };
  },
  watch: {
    getTriggerValidation(value) {
      if (value) {
        this.validateFields();
      }
    },
  },
  computed: {
    ...mapGetters({ getTriggerValidation: "insurance/getTriggerValidations" }),
  },
  created() {
    this.$emit("listExcludedCountries", this.listExcludedCountries);
    this.setCountriesList();
    if (this.$route.params.id) {
      const selectedInsurance = this.$store.getters["insurance/getSelectedInsurance"];
      this.assignSelectedLocationValues(selectedInsurance);
    }
  },
  methods: {
    selectCountry(country) {
      this.resetLocations();
      const propertyKey = this.label === "Origin" ? "origin_country" : "destination_country";
      if (this.$route.params.id) {
        this.$store.commit("insurance/updateInsuranceQuoteProperty", {
          Key: propertyKey,
          value: country,
        });
        return;
      }
      this.$store.commit("insurance/updateInsuranceQuoteProperty", {
        Key: propertyKey,
        value: country.iso2,
      });
    },
    selectLocations(event) {
      const keyPrefix = `${this.label.toLowerCase()}_`;
      const locationProperties = [
        { key: `${keyPrefix}city`, value: event.city },
        { key: `${keyPrefix}state`, value: event.state_code },
        { key: `${keyPrefix}country`, value: event.country_code },
        { key: `${keyPrefix}zip_code`, value: event.zip_code },
      ];
      locationProperties.forEach((property) => {
        this.$store.commit("insurance/updateInsuranceQuoteProperty", {
          Key: property.key,
          value: property.value,
        });
      });
      this.emptyFields.location = false;
    },
    setCountriesList() {
      this.countries = getCountriesList();
      const filteredCountries = this.countries.filter(
        (country) => !this.listExcludedCountries.includes(country.name)
      );
      this.countriesFilterList = filteredCountries;
      [this.selectedCountry] = this.countriesFilterList;
    },
    async getAllLocations(event) {
      const query = BuildLocationsQuery(event.query, this.selectedCountry.iso2);
      if (!query) {
        return;
      }
      this.loadingAutoComplete = true;
      query.country_code = this.selectedCountry.iso2;
      const response = await this.$store.dispatch("load/stops/getAllLocations", { params: query });
      this.loadingAutoComplete = false;
      if (response) {
        const filteredStops = response.map((element) => ({
          ...element,
          label: `${element.city}, ${element.state_code}, ${element.zip_code}`,
        }));
        this.filteredStops[0].options = [...filteredStops];
      }
    },
    assignSelectedCountry(selectedCountry) {
      return this.countriesFilterList.find((country) => country.iso2 === selectedCountry);
    },
    assignSelectedLocationValues(selectedInsurance) {
      if (this.label === "Origin") {
        this.locationData = {
          city: selectedInsurance.origin_city,
          country_code: selectedInsurance.origin_country,
          state_code: selectedInsurance.origin_state,
          zip_code: selectedInsurance.origin_zip_code,
          label: `${selectedInsurance.origin_city}, ${selectedInsurance.origin_state}, ${selectedInsurance.origin_zip_code}`,
        };
        this.selectedCountry = this.assignSelectedCountry(selectedInsurance.origin_country);
      }
      if (this.label === "Destination") {
        this.locationData = {
          city: selectedInsurance.destination_city,
          country_code: selectedInsurance.destination_country,
          state_code: selectedInsurance.destination_state,
          zip_code: selectedInsurance.destination_zip_code,
          // eslint-disable-next-line max-len
          label: `${selectedInsurance.destination_city}, ${selectedInsurance.destination_state}, ${selectedInsurance.destination_zip_code}`,
        };
        this.selectedCountry = this.assignSelectedCountry(selectedInsurance.destination_country);
      }
    },
    validateFields() {
      this.emptyFields.location = !this.locationData;
    },
    resetLocations(event) {
      if (!event) {
        this.locationData = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/components/RCComponents.scss";

.location-selector {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  width: 100%;
  align-items: flex-end;

  &__country {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    max-height: 200px;
    text-align: left;
  }

  &__location {
    width: 100%;
    text-align: left;
  }

  &__subtitle {
    color: $color-primary-company;
    font-size: 12px;
    font-style: italic;
    font-family: $font-family-portal;
    text-align: left;
  }
  &__section-title {
    margin: 15px 0px 5px 15px;
    text-align: left;
    color: $color-border-container;
  }
  &__suggestions {
    margin-left: 5px;
    margin-bottom: 5px;
    color: $color-primary-company;
  }
}

.dropdown {
  border-radius: 10px;
  width: 85px;
  height: 40px;
  border: 1px solid $color-border-container;
  font-family: $font-family-portal;
}

::v-deep {
  .shipping-icon--delivery {
    color: $color-primary-title;
  }
  .p-dropdown-panel {
    width: 150px;
  }
  .p-dropdown-panel .p-dropdown-header {
    padding: 0.5rem 0.2rem;
  }
  .p-dropdown-label {
    font-family: $font-family-portal;
  }
  .p-inputtext:enabled:focus {
    box-shadow: none;
    border: 1px solid #ced4da;
  }
  .p-dropdown:not(.p-disabled):hover {
    border-color: $color-border-container;
  }
}
</style>
