<template>
  <div class="insured-value">
    <div class="insured-value__container">
      <div class="insured-value__title">
        <SectionTitle
          :popoverData="popoverData"
          :mandatory="true"
          :title="$t('myInsurance.insuredValue.insuredValue')"
          :colorSecondaryTitle="true"
        >
        </SectionTitle>
      </div>
      <div class="insured-value__content">
        <div
          class="input-content"
          :class="emptyFields['insuredValue'] === true ? 'missing-field' : ''"
        >
          $
          <input
            type="text"
            min="0"
            v-model="insuredValue"
            class="input-content__input"
            :class="emptyFields['insuredValue'] === true ? 'missing-field' : ''"
            @input="inputHandler($event.target.value)"
          />
        </div>
      </div>
    </div>
    <div class="insured-value__container"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SectionTitle from "../../components/SectionTitle.vue";

export default {
  /* eslint-disable max-len */
  name: "insuredValue",
  components: {
    SectionTitle,
  },
  data() {
    return {
      insuredValue: null,
      emptyFields: {
        insuredValue: false,
      },
      selectedInsurance: null,
    };
  },
  watch: {
    getTriggerValidation(value) {
      if (value) {
        this.validateFields();
      }
    },
    insuredValue(newValue) {
      if (newValue) {
        const result = newValue || null;
        const convertedResult = result
          .toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.$nextTick(() => {
          this.insuredValue = convertedResult;
        });
        parseInt(convertedResult.replace(/,/g, ""), 10);
      }
      if (newValue) {
        this.validateFields();
      }
    },
  },
  computed: {
    ...mapGetters({ getTriggerValidation: "insurance/getTriggerValidations" }),
  },
  created() {
    this.popoverData = {
      isPopover: true,
      title: "Insured Value",
      content:
        "You can calculate the insured amount based on: - Commercial value only - CIF + 10% (commercial value + Insurance cost + Freight charges + 10% out of this sum to cover additional expenses) - Commercial value + 10%",
      target: "insuredValue",
    };
    this.insuredValue = this.$attrs.value;
    if (this.$route.params.id) {
      this.selectedInsurance = this.$store.getters["insurance/getSelectedInsurance"];
      this.assignSelectedInsuranceValues();
    }
  },
  methods: {
    inputHandler(value) {
      let parsedValue = parseInt(value.replace(/,/g, ""), 10);
      if (Number.isNaN(parsedValue)) {
        parsedValue = 0;
      }
      this.$store.commit("insurance/updateInsuranceQuoteProperty", {
        Key: "insured_value",
        value: parsedValue,
      });
    },
    validateFields() {
      if (!this.insuredValue) {
        this.emptyFields.insuredValue = true;
        return;
      }
      if (this.insuredValue > 500000) {
        this.emptyFields.insuredValue = true;
        return;
      }
      this.emptyFields.insuredValue = false;
    },
    assignSelectedInsuranceValues() {
      this.insuredValue = this.selectedInsurance.insured_value;
    },
  },
};
</script>

<style lang="scss" scoped>
.insured-value {
  display: flex;
  gap: 20px;
  width: 100%;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
  }
  &__content {
    display: flex;
    width: 100%;
  }
  &__title {
    width: 50%;
  }
}

.input-content {
  margin-top: 5px;
  padding: 5px;
  padding-left: 10px;
  background: $color-white;
  border: 1px solid $color-border-container;
  border-radius: 10px;
  @include font-input-text;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  &__input {
    border: none;
    outline: none;
    width: 100%;
  }
  &__text {
    max-width: 90px;
    width: 100%;
  }
}

.missing-field {
  background-color: $missing-fields;
}

@media (max-width: 730px) {
  .insured-value {
    display: block;
    &__container {
      width: 100%;
    }
    &__content {
      width: 100%;
    }
  }
}
</style>
