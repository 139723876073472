const loadingTextArray = {
  myLoads: [
    `Thank you for your patience!<br> 
    We are retrieving all your loads`,
    `Getting everything ready…<br>
    You can filter quotes and loads by status!`,
    `We’re almost done!<br>
    You can keep track of all your quotes and loads here!`],
  tender: [
    `Thank you for your business!<br>
    We are generating your documents…`,
    `Sending the information to the carrier…<br>
    Please make sure the origin location has a copy of all shipping documents!`,
    `We’re almost done…<br>
    Please don’t close this tab, documents will be ready soon!`],
  quoteBook: [
    `Thank you for your patience!<br>
    Checking and comparing our best prices…`,
    `Looking for something else?<br>
    Please wait here, we have more options for you!`,
    `We’re almost done!<br>
    Find the option that’s right for your company`],
};

export default loadingTextArray;
