/* eslint-disable camelcase */
function findLongestString(array) {
  return array.reduce((a, b) => (a.length > b.length ? a : b));
}

function findShortestString(array) {
  return array.reduce((a, b) => (a.length <= b.length ? a : b));
}

function getZipcode(queries, countryCode) {
  // Regexp to check if the string contains only numbers
  const numbersRegexp = new RegExp(/^\d+$/g);
  const canadaRegexp = new RegExp(/^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d\s]*$/g);
  if (countryCode === "CA") {
    return queries.find((item) => canadaRegexp.test(item));
  }
  return queries.find((item) => numbersRegexp.test(item));
}

export default function buildLocationsQuery(value, countryCode) {
  // Regexp to check if the string contains only letters
  const lettersRegexp = new RegExp(/^[a-z A-Z]+$/);
  let city = "";
  let state_code = "";
  if (value.length < 3) {
    return null;
  }
  const splittedQuery = value.split(/[,]+/).map((item) => item.trim());
  if (splittedQuery.length > 3) {
    return null;
  }
  const zip_code = getZipcode(splittedQuery, countryCode);
  const stringQueries = splittedQuery.filter((item) => lettersRegexp.test(item));
  if (stringQueries.length) {
    city = findLongestString(stringQueries);
    if (stringQueries.length > 1) {
      state_code = findShortestString(stringQueries);
    }
  }
  const query = {
    ...(zip_code && { zip_code }),
    ...(city && { city }),
    ...(state_code && { state_code }),
  };
  return Object.keys(query).length ? query : null;
}
