<template>
  <div class="stop-insurance">
    <div class="stop-insurance__origin">
      <div class="origin-title">
        <SectionTitle
          :popoverData="popoverData"
          :mandatory="true"
          :title="$t('myInsurance.route.origin')"
        ></SectionTitle>
      </div>
      <div class="location">
        <LocationSelector label="Origin" />
        <u class="underline" @click="handleClick">Excluded countries origin/destination</u>
      </div>
    </div>
    <div class="stop-insurance__destination">
      <div class="origin-title">
        <SectionTitle
          :popoverData="popoverData"
          :mandatory="true"
          :title="$t('myInsurance.route.destination')"
        ></SectionTitle>
      </div>
      <div class="location">
        <LocationSelector @listExcludedCountries="handleMessage" label="Destination" />
        <div v-if="showModal" class="custom-modal">
          <CustomModal v-model="showModal" modalTitle="Excluded Countries" centered>
            <template #modal-content>
              <p class="custom-modal__content">
                {{ listExcluded.join(", ") }}.
              </p>
            </template>
          </CustomModal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomModal from "@/components/CustomModal.vue";
import SectionTitle from "../../components/SectionTitle.vue";
import LocationSelector from "../../components/LocationSelector.vue";

export default {
  name: "StopInsurance",
  components: {
    SectionTitle,
    LocationSelector,
    CustomModal
  },

  data() {
    return {
      popoverData: {},
      showModal: false,
      listExcluded: [],
    };
  },
  created() {
    this.popoverData = {
      isPopover: false,
    };
  },
  methods: {
    handleClick() {
      this.showModal = true;
    },
    handleMessage(value) {
      this.listExcluded = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.stop-insurance {
  display: flex;
  gap: 20px;
  width: 100%;
  &__origin,
  &__destination {
    width: 50%;
  }
}

.location {
  width: 100%;
  &__country {
    width: 100px;
  }
  &__route {
    width: 100%;
  }
}
.underline {
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
  cursor: pointer;
  color: $color-primary-company;
  font-size: 12px;
  font-style: italic;
  font-family: $font-family-portal;
}
.custom-modal {
  &__content {
    margin-bottom: 0px;
    color: $color-primary-company;
    font-size: 14px;
    font-style: italic;
    font-family: $font-family-portal;
  }
}

@media (max-width: 730px) {
  .stop-insurance {
    flex-direction: column;
    &__origin,
    &__destination {
      width: 100%;
    }
  }
}
</style>
