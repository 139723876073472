import * as yup from "yup";

const allowedRoles = ["glt admin", "cargocare admin"];

const QuoteInsuranceScheme = yup.object().shape({
  origin_city: yup.string().required("Origin is required").typeError("Origin is required"),
  origin_country: yup.string().required("Origin is required").typeError("Origin is required"),
  origin_zip_code: yup.string().required("Origin is required").typeError("Origin is required"),
  destination_city: yup
    .string()
    .required("Destination is required")
    .typeError("Destination is required"),
  destination_country: yup
    .string()
    .required("Destination is required")
    .typeError("Destination is required"),
  destination_zip_code: yup
    .string()
    .required("Destination is required")
    .typeError("Destination is required"),
  insured_value: yup
    .number()
    .positive("Insured value must be a positive number")
    .when("role", {
      is: (value) => !allowedRoles.includes(value.trim().toLowerCase()),
      then: yup
        .number()
        .positive("Insured value must be a positive number")
        .required("Insured value is required")
        .test(
          "max",
          "The amount exceeds the limit on the system, and a Specialist will contact you to proceed.",
          (value) => value <= 500000
        ),
      else: yup
        .number()
        .positive("Insured value must be a positive number")
        .required("Insured value is required"),
    }),
  condition: yup.string().required("Condition is required").typeError("Condition is required"),
  commodity: yup.string().required("Commodity is required").typeError("Commodity is required"),
  conveyance: yup
    .string()
    .required("Conveyance type is required")
    .typeError("Conveyance type is required"),
  role: yup.string(),
});

export default QuoteInsuranceScheme;
