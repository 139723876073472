<template>
  <div>
    <ul class="custom-modal-content">
      <li class="custom-modal-item" v-for="item in commoditiesExcluded" :key="item.id">
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ModalCommoditiesExcluded",
  components: {},
  data() {
    return {
      commoditiesExcluded: [
        "Aircraft, boats, ships",
        "Antiques not part of a Household Goods and Personal Effects consignment",
        "Asbestos",
        "Bulk car contracts (New & Used)",
        "Cargo shipped in bulk (not in containers or small items)",
        "Cash, bullion, deeds, bonds, stamps, duty stamps, medals, coins, cash in transit",
        "Cellphones ",
        "Chinese Groundnuts",
        "Cigarettes",
        "Classic Cars or any vehicle over 12 years of age",
        "Computer Chips memory boards & similar high value components",
        "Explosives; Pyrotechnic Products; Matches; Pyrophoric Alloys",
        "Fine wines, spirits or similar",
        "Precision Instruments or Machinery which is prone to breakage",
        "Fishmeal & Fish catch",
        "Raw Hides and Skins; leather",
        "Blood and Life science products",
        "Furs, Furs Garments, designer clothing",
        "Heavy Duty Vehicles",
        "Heavylift Cargo",
        "Jewellery, watches, trinkets",
        "Personal ornaments, precious stones & metals.",
        "Kamigoto Project",
        "Livestock, Live flowers, live animals",
        "Precision Instruments or Machinery",
        "Fashion and Designerware",
        "Scientific and Computerised Control Panels",
      ],
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.custom-modal-item {
  color: $color-primary-company;
  font-size: 14px;
  font-style: italic;
  font-family: $font-family-portal;
}
.custom-modal-content {
  margin-bottom: 8px;
  padding: 8px 15px;
}
</style>
