/* eslint-disable max-len */

export default [
  "Adjustable monitor stand, keyboard and mouse pad, laptop case",
  "Agricultural products, Agricultural equipment",
  "Air conditioners, AC units, A/C parts, fans, transformer, motor, engines, generators",
  "Alcoholic Beverages; wine, beer, lager, cider in bottles, cans, cartons, kegs, barrels or drums",
  "Audio Equipment, Radios, TV's, DVD's and Video Recorders, ",
  "Auto spare parts, bikes spare parts, accessories for cars, tires, tyres",
  "Automotive products, automotive spare parts",
  "Batteries",
  "Beverages",
  "Bicycles parts, bike accesories",
  "Bikes, bicycles, Fishing, Hunting Equipment, Paintball Equipment",
  "Binoculars, Telescope",
  "Biological cleaners",
  "Blankets, towels, sheets",
  "Bookcases and shelving units",
  "Books, Magazines, Catalogues, Printed products",
  "Branded Goods; Cosmetics & Commodities, Confectionery",
  "Broadband Equipment, broadcasting equipment",
  "Building materials, Builiding suppplies, Building equipment",
  "Cabinets, cupboard, closets",
  "Calculators",
  "Camping & Mounting Equipment",
  "Candles, candle wax",
  "Carpet tile",
  "Cases",
  "Castings",
  "Catheters",
  "Cellulose Board",
  "Chairs",
  "Chemical products",
  "Chemicals ",
  "Chemicals in Bottles, Tins, CansChemicals",
  "Cleaning products",
  "Coating, coating solution, coating products",
  "Construction Equipment",
  "Conveyors",
  "Computer part, computer accessories",
  "Curtains",
  "Cycling Accessories, cycling equipment, cycling apparel",
  "Decoration Products",
  "Display Furniture, Advertising Products",
  "Dive equipment",
  "Domestic Electrical Appliances ",
  "Dried Foodstuffs; Beans, Rice, Pulses, cookies, Herbs and Spices, cans, sauces, salt, seeds",
  "Electric Bikes and parts",
  "Electric components",
  "Electric Conduit and Conduit Fitting",
  "Electric Wire and Cable, Electrical Connectors, Lugs",
  "Electronic Toys, Video Games, Consoles",
  "Encapsulated Pigment",
  "Essential oils",
  "Ethanol",
  "Examination tables, examination chairs",
  "Excavator parts, Tractors Parts, ",
  "Exercise equipmet, gym equipment",
  "Eyewear",
  "Face masks, Liquid hand sanitizer",
  "Fishing equipment, accessories and supplies",
  "Fittings",
  "Flame resistant coveralls, safety coveralls, ",
  "Flat packed Furniture, new furniture, furniture",
  "Foodstuff",
  "Garage equipment, Gardening equipment, Ironmongery, Screws, Nails, Taps & Wire, ",
  "Garments",
  "General sporting goods",
  "Generators, Road Works Equipment, Heavy Rollers, Drilling, Digging Equipment",
  "Gym Equipment, Cardio Equipment, Physical Fitness equipment",
  "Hardware",
  "Headphones, Speakers, Microphones",
  "Industrial Machinery (non heavy)",
  "Industrial Products",
  "Insulators, Encoders",
  "Ironware - Door & Window Ironware",
  "iphone cases",
  "Keyboards, Mouse, computer cable",
  "Kitchen equipment, cooking accessories, cooking utensils",
  "Knitting, Textile Machinery",
  "Laminated floor",
  "Landscape products, Landscape lamps",
  "Leather goods, Leatherwear, Finished Leather, bovine leather",
  "Leisure Articles",
  "Litter Harness, Carabiners, Rescue litter",
  "Lighting fixtures, LED lamps",
  "Lockers",
  "Lubricants, Grease, oil products , synthetic Lubricants",
  "Machinery for Railways",
  "Made up Clothing, Shoes",
  "Medical accesories",
  "Medical equipment (non electronics, non fragiles), Medical Clothing, Dental Equipment, Wheelchairs",
  "Miscellaneous manufactured products",
  "Musical Instruments (Non digital)",
  "Nautical control parts, marine doors",
  "New Machinery, Machinery Parts, Spare Parts",
  "Non alcoholic beverages",
  "Office partitions, Cabinets, Office Sundries",
  "Office supplies, Office equipment",
  "Outdoor furniture",
  "Ovens, Refrigerators, Freezers, Washers, Dryers, Dishwashers, Microwaves",
  "Packaging materials, Packaging supplies, Packaging equipment",
  "Packing Material, Packing equipment",
  "Paint products, paint equipment",
  "Paint, ink",
  "Panels",
  "Paper, Paper Products, Stationery, Pulp, Paperboard",
  "Patio furniture",
  "Perfumery Products, Beauty Products",
  "Perfumes, Cosmetics, Toilet preparations",
  "Pets Products, Pets food",
  "Pharmaceutical Products in Bottles (non temp controlled),",
  "Plastic, plastic holloware, plastic fence, plastic fasteners, plastic extrusions, plastic glides",
  "Playground Equipment",
  "printers for jeans",
  "Racket Sports - Apparel",
  "Regulators, blowers",
  "Religious articles non fragiles",
  "Replacement parts for control valves",
  "Restaurant equipment",
  "Rubber, Rubber mats, rubber gloves, rubber gaskets",
  "Safety fuses,",
  "Seals, Transmitter, Control valve, Diaphragm, Repair kit",
  "Security Equipment, Security systems, Security seals",
  "Sensors and Alarms, Smart Sensors",
  "Sewing machines, Textile machinery,",
  "Skateboards, Snowboards, Outdoor equipments",
  "Sport Goods, Sports, Leisurewear",
  "Sports set, Sports equipment",
  "Stainless steel products",
  "Steel, aluminium parts, aluminium profiles, Metal, Articles metal, Alloy",
  "Storage units",
  "Supplements, Vitamins, Cereals, Relief supplies",
  "Swimming Pool - Equipment & Supplies",
  "Switches, Sockets, Plugs, Connectors, Controllers, Transceivers, Relays",
  "Tape, Foam, Plastic film",
  "Tara Powder",
  "Telecommunication Equipment, teleprescense equipment",
  "Temperature Controls Devices",
  "Textiles, Piece Goods, Fabrics, Haberdashery, Cloth, Finished Wool, Finished cotton & Yarn.",
  "Thermostat, Cooling thermostat, cooling equipment",
  "Tins, Cans, Paper, Sachets including liquid items in Tins",
  "Tonners, toners, cartridges",
  "Tools",
  "Toys, Games",
  "Transfer switch",
  "Transformers",
  "Turntables, Shelf system Entertainment centers,",
  "Typewriters",
  "TV's tables, TV's stands and Enterteinment set",
  "Valve kits, Pumps, Engines, filters",
  "Waste and scrap",
  "Water Based adhesives",
  "White Goods Cookers",
  "Wire, cable reel, cable",
  "Computer equipment, computer hardware",
  "Desktop Computers, Servers, Monitors, Photocopiers, copiers",
  "Duplicators, External Hard Drives, Flash Memory and Readers, Flash Drives",
  "Electronic equipment, electronic devices",
  "ethernet or wireless card, Vedeo card",
  "Hard drive, Portable hard drive ",
  "Hard drives, Solid State Drive, Processors, Optical Drive DVD/RW,",
  "iPads, Tablets",
  "Laptops",
  "Motherboards, CPU, Central processing units, GPU, RAM memories,",
  "Computer Chips, memory boards & similar high value components",
  "Plasma and LCD televisions / screens,",
  "Printers",
  "Projectors, Airphones",
  "PSU, Power Supply units, Storage Devices",
  "Radiological Equipment",
  "Satellite Boxes, Cameras",
  "Scientific devises",
  "Sim cards",
  "Telecommunication Equipment, teleprescense equipment",
  "USB",
  "wireless keyboard, wireless speaker,",
  "dryice",
  "Fruits and vegetables",
  "gelpack",
  "icepacks",
  "meat, chicken, fish",
  "medical equipment with temperature control",
  "cheese",
  "Crystal and ceramic products",
  "Enamelware, Glassware, Sanitaryware, Chinaware, Pottery, Crystalware, Bulbs, earthenware",
  "Glass, Glass items, Glass lights, Glass bottles",
  "Marble, tiles, porcelains, optical instruments, valuable collectibles,",
  "Neon, Tubes, Cathode Ray Tubes, neon valves",
  "Slate, Granite and similar Slabs or Blocks (individually separated and suitably packed or protected for transit in crates or cases)",
  "Tempered Glass",
  "Toilet articles, porcelains, Stone articles,",
  "X-ray equipment,",
  "Household Goods & Personal Effects Owner & Professionally packed.",
  "HHG, hhg, HHG's",
  "used furniture, used sofas, used beds, used tables, used chairs",
  "Forklift Trucks",
  "Heavy machinery",
  "Heavy Manufacturing",
  "Motor Homes and Caravans shipped underdeck or in Containers.",
  "Motor Vehicles",
  "Motorbikes, motorcycle, scooters, electric scooters",
  "Mustang car, ford pickup, mazda, toyota, Volkswagen, Volvo, Audi, Mercedez, Cadillac",
  "Special purpose Motor Vehicles",
  "Tractors, crane, excavators, bus, autobus,",
  "vehicles, car, auto, automobile, pickups, suv, electric cars",
  "Yamaha, Suzuki, BMW, Honda, Harley Davidson",
  "Yellow machines",
  "Aircraft parts",
  "Aircraft tires",
  "Aircraft turbines, aircraft engines"
];
