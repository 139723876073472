<template>
  <div class="conveyance-selector">
    <div class="buttons-container">
      <button
        v-for="(conveyance, index) in localConveyanceData"
        :key="index"
        :class="[
          conveyance.class,
          { active: chosenConveyance === conveyance.value },
          'button-container',
          {
            'button-disabled':
              restrictGroundOrRail && conveyance.id === 'ground' && !isFilterInstance,
          },
        ]"
        :id="conveyance.id"
        @click="chooseConveyance(conveyance.value)"
      >
        <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
          <path :d="conveyance.svg" />
        </svg>
        <span>{{ conveyance.name }}</span>
        <b-popover
          v-if="conveyance.message && !isFilterInstance"
          :target="conveyance.id"
          triggers="hover"
          placement="top"
        >
          <template #title>{{ conveyance.name }}</template>
          {{ conveyance.message }}
        </b-popover>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import conveyanceData from "./conveyanceData";

export default {
  name: "ConveyanceComponent",
  components: {},
  props: {
    propValue: String,
    isFilterInstance: Boolean,
  },
  data() {
    return {
      localConveyanceData: [...conveyanceData],
      chosenConveyance: null,
      selectedInsurance: null,
      key: 0,
    };
  },
  watch: {
    propValue: {
      immediate: true,
      handler() {
        if (this.propValue) {
          this.chosenConveyance = this.propValue;
          this.$emit("getConveyanceInformation", this.propValue);
        }
      },
    },
  },
  computed: {
    ...mapGetters({ insuranceQuote: "insurance/getInsuranceQuote" }),
    restrictGroundOrRail() {
      const groundOrRailAllowedCountries = ["US", "CA"];
      const originCountry = this.insuranceQuote.origin_country;
      const destinationCountry = this.insuranceQuote.destination_country;
      if (!originCountry || !destinationCountry) {
        return false;
      }
      if (
        groundOrRailAllowedCountries.includes(originCountry) &&
        groundOrRailAllowedCountries.includes(destinationCountry)
      ) {
        return false;
      }
      if (this.insuranceQuote.conveyance === "Ground or Rail") {
        this.chooseConveyance("");
      }
      return true;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.selectedInsurance = this.$store.getters["insurance/getSelectedInsurance"];
      this.assignSelectedInsuranceValues();
    }
  },
  methods: {
    chooseConveyance(value) {
      this.chosenConveyance = value;
      this.$store.commit("insurance/updateInsuranceQuoteProperty", {
        Key: "conveyance",
        value: this.chosenConveyance,
      });
      this.$emit("getConveyanceInformation", value);
    },
    assignSelectedInsuranceValues() {
      this.chosenConveyance = this.selectedInsurance.conveyance;
    },
    resetFilters() {
      this.chosenConveyance = null;
    },
    // restrictGroundOrRail(insuranceQuote) {
    //   const groundOrRailAllowedCountries = ["US", "CA"];
    //   const originCountry = insuranceQuote.origin_country;
    //   const destinationCountry = insuranceQuote.destination_country;
    //   if (!originCountry || !destinationCountry) {
    //     return false;
    //   }
    //   if (
    //     !groundOrRailAllowedCountries.includes(originCountry) &&
    //     !groundOrRailAllowedCountries.includes(destinationCountry)
    //   ) {
    //     return true;
    //   }
    //   return false;
    // },
  },
};
</script>

<style lang="scss" scoped>
.air-btn,
.ground-rail-btn,
.ocean-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 145px;
  height: 36px;
  background: white;
  border: 1px solid $color-border-container;
  border-radius: 25px;
  padding: 5px 10px 5px 10px;
  align-content: space-between;
  font-size: 13px;
}
.active {
  background-color: $color-primary-trigger-button !important;
  transition: 0.3s ease all;
  & > span {
    color: white !important;
  }
  & > .svg-icon {
    fill: white !important;
  }
}
.air-btn > span,
.ground-rail-btn > span,
.ocean-btn > span {
  padding-left: 10px;
  color: $color-primary-trigger-button;
}
.svg-icon {
  fill: $color-primary-trigger-button;
}

.air-btn:hover,
.ground-rail-btn:hover,
.ocean-btn:hover {
  background: $color-primary-trigger-button;
  transition: 0.3s ease all;
  color: white;
}
.air-btn:hover > .svg-icon,
.air-btn:hover > span {
  color: white;
  fill: white;
}

.ground-rail-btn:hover > .svg-icon,
.ground-rail-btn:hover > span {
  color: white;
  fill: white;
}
.ocean-btn:hover > .svg-icon,
.ocean-btn:hover > span {
  color: white;
  fill: white;
}
.conveyance-selector {
  display: flex;
  gap: 20px;
}

.covenyance-filter-selector {
  display: flex;
  flex-direction: column;
  gap: 0px !important;
}

.buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 5px;
}

.button-disabled {
  pointer-events: none;
  opacity: 0.4;
}

@media (max-width: 500px) {
  .buttons-container {
    flex-direction: column;
  }
  .conveyance-selector {
    width: 100%;
    display: block;
  }
}
</style>
