<template>
  <div class="quote-insurance">
    <b-overlay :show="creatingInsuranceQuote" rounded="sm" class="create-load-overlay">
      <div class="quote-insurance__content" v-if="!loading">
        <ConveyanceInsurance ref="conveyance" class="mb-4" />
        <StopInsurances class="mb-4" />
        <InsuredValue class="mb-4" />
        <DescriptionInsurance />
        <div class="quote-insurance">
          <button class="button-quote" @click="quoteButtonHandler">
            {{ $t("myInsurance.quote") }}
          </button>
        </div>
      </div>
      <SkeletonQuoteInsurance v-else />
      <template v-if="creatingInsuranceQuote" #overlay>
        <div class="create-insured-icon">
          <b-icon icon="truck" font-scale="3" animation="cylon"></b-icon>
          <FadeAnimationTextArray :textArray="loadingTextArray" />
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import FadeAnimationTextArray from "@/components/FadeAnimationTextArray.vue";
import LoadingTextArray from "@/utils/LoadingTextArray";
import QuoteInsuranceScheme from "@/schemas/QuoteInsuranceScheme";
import StopInsurances from "./components/StopInsurance.vue";
import ConveyanceInsurance from "./components/ConveyanceInsurance.vue";
import DescriptionInsurance from "./components/DescriptionInsurance.vue";
import InsuredValue from "./components/InsuredValue.vue";
import SkeletonQuoteInsurance from "./components/SkeletonQuoteInsurance.vue";

export default {
  name: "QuoteInsurance",
  components: {
    StopInsurances,
    DescriptionInsurance,
    ConveyanceInsurance,
    InsuredValue,
    FadeAnimationTextArray,
    SkeletonQuoteInsurance,
  },
  data() {
    return {
      insuranceQuoteData: null,
      isValidScheme: false,
      conveyanceDataValidation: null,
      loadingTextArray: LoadingTextArray.quoteBook,
      creatingInsuranceQuote: false,
      loading: false,
      selectedInsurance: null,
      role: null
    };
  },
  watch: {
    creatingInsuranceQuote(value) {
      if (value) {
        window.onbeforeunload = function () {
          return "handle your events or msgs here";
        };
      } else {
        window.onbeforeunload = null;
      }
    },
  },
  async created() {
    const insuranceId = this.$route.params.id;
    this.role = this.$store.getters["login/getTokenInfo"].role;
    if (insuranceId) {
      this.loading = true;
      this.selectedInsurance = await this.getInsuranceInfoById(insuranceId);
      this.setVuexSelectedInsurance();
      this.loading = false;
    }
  },
  methods: {
    async getInsuranceInfoById(insuranceId) {
      const insurance = await this.$store.dispatch("insurance/getInsuranceById", insuranceId);
      return insurance;
    },
    async createInsuranceQuote(insuranceQuoteData) {
      const response = await this.$store.dispatch(
        "insurance/createInsuranceQuote",
        insuranceQuoteData
      );
      return response;
    },
    async updateInsuranceQuote(insuranceQuoteData) {
      const response = await this.$store.dispatch("insurance/updateInsuranceQuote", {
        id: this.$route.params.id,
        body: insuranceQuoteData,
      });
      return response;
    },
    async quoteButtonHandler() {
      this.$store.commit("insurance/setTriggerValidations");
      const insuranceQuoteData = this.$store.getters["insurance/getInsuranceQuote"];
      insuranceQuoteData.role = this.role;
      /* Validation for endpoint consumption */
      const schemaValidation = await this.validateSchema(insuranceQuoteData);
      if (!schemaValidation) {
        return;
      }
      this.creatingInsuranceQuote = true;
      let certificateInsuranceResponse;
      if (this.$route.params.id) {
        certificateInsuranceResponse = await this.updateInsuranceQuote(insuranceQuoteData);
      } else {
        certificateInsuranceResponse = await this.createInsuranceQuote(insuranceQuoteData);
      }
      if (!certificateInsuranceResponse) {
        this.creatingInsuranceQuote = false;
        return;
      }
      this.clearVuexInsuranceQuote();
      this.$router.push({
        name: "InsuranceSelection",
        params: { id: certificateInsuranceResponse.id },
      });
      this.creatingInsuranceQuote = false;
    },
    async validateSchema(insuranceQuote) {
      let schemaValidation = true;
      try {
        await QuoteInsuranceScheme.validate(insuranceQuote, {
          abortEarly: false,
        });
      } catch (error) {
        const maxError = error.inner.find((element) => element.type === "max");
        if (maxError) {
          const contactUsConfirmation = await this.swal({
            title: "Limit restrictions",
            icon: "warning",
            text: `The insured value exceeds the system limit. Contact us if you need more assistance with your insurance quote, or go back to update the insured value.`,
            dangerMode: false,
            buttons: ["Cancel", "Contact us!"],
          });
          if (contactUsConfirmation) {
            this.uploadDataToSendEmail();
          }
        } else {
          this.swal({
            title: "Missing fields",
            text: error.inner[0].message,
            icon: "warning",
          });
        }
        schemaValidation = false;
      }
      return schemaValidation;
    },
    setVuexSelectedInsurance() {
      const insuranceQuoteSchema = {
        commodity: this.selectedInsurance.commodity,
        condition: this.selectedInsurance.condition,
        conveyance: this.selectedInsurance.conveyance,
        destination_city: this.selectedInsurance.destination_city,
        destination_country: this.selectedInsurance.destination_country,
        destination_state: this.selectedInsurance.destination_state,
        destination_zip_code: this.selectedInsurance.destination_zip_code,
        insured_value: this.selectedInsurance.insured_value,
        is_domestic: this.selectedInsurance.is_domestic,
        origin_city: this.selectedInsurance.origin_city,
        origin_country: this.selectedInsurance.origin_country,
        origin_state: this.selectedInsurance.origin_state,
        origin_zip_code: this.selectedInsurance.origin_zip_code,
      };
      this.$store.commit("insurance/setInsuranceQuote", insuranceQuoteSchema);
    },
    clearVuexInsuranceQuote() {
      this.$store.commit("insurance/resetInsuranceQuote");
    },
    uploadDataToSendEmail() {
      const insuranceQuoteData = this.$store.getters["insurance/getInsuranceQuote"];
      const link = document.createElement("a");
      const body = encodeURIComponent(
        `Your quotation is via :  ${insuranceQuoteData.conveyance}, From : ${insuranceQuoteData.origin_city}, 
              To : ${insuranceQuoteData.destination_city}, and The insure value : ${insuranceQuoteData.insured_value}`.trim()
      );
      const subject = encodeURIComponent("Request for insurance coverage over $500.000".trim());
      link.href = `mailto:insurance@goglt.com?subject=${subject}&body=${body}`;
      link.target = "_blank";
      link.dispatchEvent(new MouseEvent("click"));
    },
  },
};
</script>

<style lang="scss">
.swal-modal .swal-button--confirm {
  background: $color-primary-trigger-button !important;
}

.button-quote {
  border-radius: 15px;
  margin-right: 10px;
  width: 25%;
  border: none;
  background-color: $color-primary-trigger-button;
  color: $color-white;
  height: 40px;
  font-weight: bold;
  @include font-button-text;

  &:hover {
    background-color: $color-primary-trigger-button-hover;
  }

  .create-insured-icon {

    svg,
    p {
      color: $color-primary-company;
      font-weight: bold;
    }
  }

  @media (max-width: 500px) {
    width: 100%;
    margin: 20px auto 0px auto;
  }
}
</style>
