<template>
  <div class="custom-rc-input">
    <RCInput
      v-model="localValue"
      :label="label"
      :floatLabel="floatLabel"
      :type="type"
      :min="min"
      :max="max"
      :maxlength="maxlength"
      :minlength="minlength"
      :disabled="disabled"
      :pattern="pattern"
      :suggestions="suggestions"
      :positiveNumbers="positiveNumbers"
      :requiredField="requiredField"
      :forceSelection="forceSelection"
      @blur="$emit('blur', localValue)"
      @focus="$emit('focus', localValue)"
      @change="$emit('change', localValue)"
    />
  </div>
</template>

<script>
import RCInput from "rcat-components/RCInput";

export default {
  name: "RCCustomInput",
  props: {
    // This props handles the v-model directive
    value: {
      required: false,
    },
    label: String,
    floatLabel: Boolean,
    type: {
      type: String,
      default: "text",
    },
    min: {
      type: Number,
      default: 0,
    },
    max: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    pattern: {
      type: String,
      required: false,
    },
    suggestions: {
      type: Array,
      required: false,
      default: () => [],
    },
    forceSelection: {
      type: Boolean,
      required: false,
      default: false,
    },
    positiveNumbers: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    minlength: {
      type: Number,
      required: false,
    },
    maxlength: {
      type: Number,
      required: false,
    }
  },
  components: {
    RCInput,
  },
  data() {
    return {
      localValue: this.value || "",
    };
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
    localValue(newValue) {
      this.$emit("input", newValue);
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .rc-input-container {
    width: 100% !important;
    border: 1px solid $color-border-container !important;
  }
  .rc-suggestions {
    color: $color-primary-company !important;
  }
}
</style>
