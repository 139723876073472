var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conveyance-selector"},[_c('div',{staticClass:"buttons-container"},_vm._l((_vm.localConveyanceData),function(conveyance,index){return _c('button',{key:index,class:[
        conveyance.class,
        { active: _vm.chosenConveyance === conveyance.value },
        'button-container',
        {
          'button-disabled':
            _vm.restrictGroundOrRail && conveyance.id === 'ground' && !_vm.isFilterInstance,
        },
      ],attrs:{"id":conveyance.id},on:{"click":function($event){return _vm.chooseConveyance(conveyance.value)}}},[_c('svg',{staticClass:"svg-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","height":"1em","viewBox":"0 0 640 512"}},[_c('path',{attrs:{"d":conveyance.svg}})]),_c('span',[_vm._v(_vm._s(conveyance.name))]),(conveyance.message && !_vm.isFilterInstance)?_c('b-popover',{attrs:{"target":conveyance.id,"triggers":"hover","placement":"top"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(conveyance.name))]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(conveyance.message)+" ")]):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }