<template>
  <div class="skeleton-quote-insurance">
    <div class="d-flex flex-row field col-md-6 col-12 justify-content-between mb-3 mt-5 conveyance-container">
      <Skeleton class="skeleton mb-2" width="32%" height="40px"></Skeleton>
      <Skeleton class="skeleton mb-2" width="32%" height="40px"></Skeleton>
      <Skeleton class="skeleton mb-2" width="32%" height="40px"></Skeleton>
    </div>
    <div class="d-flex mb-3 flex-md-row flex-column mt-5 mb-2">
      <div class="d-flex flex-row field col-12 col-md-6 gap-1">
        <Skeleton class="" width="25%" height="50px"></Skeleton>
        <Skeleton class="ml-4" width="100%" height="50px"></Skeleton>
      </div>
      <div class="d-flex flex-row field col-12 col-md-6">
        <Skeleton class="" width="25%" height="50px"></Skeleton>
        <Skeleton class="ml-4" width="100%" height="50px"></Skeleton>
      </div>
    </div>
    <div class="col-6 mb-5 mt-5">
      <Skeleton width="100%" height="50px"></Skeleton>
    </div>
    <div class="d-flex mb-3">
      <div class="d-flex flex-row field col-6">
        <Skeleton width="100%" height="50px"></Skeleton>
      </div>
      <div class="d-flex flex-row field col-6">
        <Skeleton width="100%" height="50px"></Skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";

export default {
  name: "SkeletonQuoteInsurance",
  components: {
    Skeleton
  }
};
</script>

<style lang="scss" scoped>
::v-deep .p-skeleton {
  background-color: #d9d9d9;
}
@media (max-width: 760px) {
  .gap-1 {
    margin-bottom: 15px !important;
  }
}
@media (max-width: 500px) {
  .conveyance-container {
    width: 100% !important;
    margin: auto !important;
    flex-direction: column !important;
    .skeleton {
      width: 50% !important;
      margin: 0px auto 5px auto;
    }
  }
}
</style>
