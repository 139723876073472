<template>
  <div class="description-insurance">
    <div class="description-insurance__commodity">
      <div class="description-insurance__title">
        <SectionTitle
          :mandatory="true"
          :title="$t('myInsurance.commodity.commodity')"
          :popoverData="popoverData"
        ></SectionTitle>
      </div>
      <RCInput
        class="country_input-searcher"
        :class="emptyFields['commodity'] === true ? 'missing-field' : ''"
        :label="$t('myInsurance.commodity.commodity')"
        v-model="descriptionData.commodity"
        :suggestions="localCommoditiesData"
        :forceSelection="true"
        type="text"
      >
        <template #icon>
          <b-icon icon="geo-alt" class="shipping-icon--delivery"></b-icon>
        </template>
      </RCInput>
      <div class="description-insurance__message">
        <span class="message-underline"
          ><u class="underline" @click="handleClick">
            All items on <strong>this list</strong>, or not recognized, can be insured with previous
            authorization from underwriters.</u
          >
          Please contact us at insurance@goglt.com</span
        >
        <div v-if="showModal">
          <CustomModal v-model="showModal" modalTitle="Excluded Commodities" centered size="lg">
            <template #modal-content>
              <ModalCommoditiesExcluded />
            </template>
          </CustomModal>
        </div>
      </div>
    </div>
    <div class="description-insurance__condition">
      <div class="description-insurance__title">
        <SectionTitle
          :mandatory="true"
          :title="$t('myInsurance.condition.condition')"
          :popoverData="popoverData"
        ></SectionTitle>
      </div>
      <RCSelect
        class="country_input-searcher"
        :class="emptyFields['condition'] === true ? 'missing-field' : ''"
        :label="$t('myInsurance.condition.condition')"
        :floatLabel="false"
        type="text"
        :requiredField="true"
        :suggestions="conditionSuggestions"
        v-model="descriptionData.condition"
      >
        <template #icon>
          <b-icon icon="geo-alt" class="shipping-icon--delivery"></b-icon>
        </template>
      </RCSelect>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomModal from "@/components/CustomModal.vue";
import RCSelect from "@/components/RCComponents/CustomRCSelect.vue";
import RCInput from "@/components/RCComponents/CustomRCInput.vue";
import ValidateEmptyFields from "@/utils/validateEmptyFields";
import SectionTitle from "../../components/SectionTitle.vue";
import commoditiesData from "../../commoditiesData";
import ModalCommoditiesExcluded from "./ModalCommoditiesExcluded.vue";

export default {
  name: "DescriptionInsurance",
  components: {
    SectionTitle,
    RCSelect,
    RCInput,
    CustomModal,
    ModalCommoditiesExcluded,
  },
  data() {
    return {
      descriptionData: {
        commodity: "",
        condition: "",
      },
      emptyFields: {
        commodity: false,
        condition: false,
      },
      popoverData: {},
      conditionSuggestions: [
        { label: "Brand New Goods", value: "Brand New Goods" },
        { label: "Used Goods", value: "Used Goods" },
        { label: "Reconditioned as New", value: "Reconditioned as New" },
      ],
      localCommoditiesData: [...commoditiesData],
      selectedInsurance: null,
      showModal: false,
    };
  },
  watch: {
    "descriptionData.commodity": {
      deep: true,
      immediate: true,
      handler() {
        this.$store.commit("insurance/updateInsuranceQuoteProperty", {
          Key: "commodity",
          value: this.descriptionData.commodity,
        });
        if (this.descriptionData.commodity) {
          this.validateFields();
        }
      },
    },
    "descriptionData.condition": {
      deep: true,
      immediate: true,
      handler() {
        this.$store.commit("insurance/updateInsuranceQuoteProperty", {
          Key: "condition",
          value: this.descriptionData.condition,
        });
        if (this.descriptionData.condition) {
          this.validateFields();
        }
      },
    },
    getTriggerValidation(value) {
      if (value) {
        this.validateFields();
      }
    },
  },
  computed: {
    ...mapGetters({ getTriggerValidation: "insurance/getTriggerValidations" }),
  },
  created() {
    this.popoverData = {
      isPopover: false,
    };
    if (this.$route.params.id) {
      this.selectedInsurance = this.$store.getters["insurance/getSelectedInsurance"];
      this.assignSelectedInsuranceValues();
    }
  },
  methods: {
    /* Validations Empty Fields */
    validateFields() {
      const validate = ValidateEmptyFields(this.descriptionData);
      this.resetEmptyFieldsValues();
      validate.emptyFields.forEach((field) => {
        this.emptyFields[field] = true;
      });
    },
    resetEmptyFieldsValues() {
      const keys = Object.keys(this.emptyFields);
      keys.forEach((key) => {
        this.emptyFields[key] = false;
      });
    },
    assignSelectedInsuranceValues() {
      this.descriptionData = {
        commodity: this.selectedInsurance.commodity,
        condition: this.selectedInsurance.condition,
      };
    },
    handleClick() {
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/RCComponents.scss";
.description-insurance {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  &__message {
    display: flex;
    align-items: flex-start;
    color: $color-primary-company;
    font-size: 10px;
    font-style: italic;
    font-family: $font-family-portal;
    text-align: start;
  }
  &__commodity,
  &__condition {
    width: 50%;
  }
}

.underline {
  cursor: pointer;
}

.message-underline {
  font-size: 12px;
}

@media (max-width: 730px) {
  .description-insurance {
    flex-direction: column;
    &__commodity,
    &__condition {
      width: 100%;
    }
  }
}
::v-deep .rc-form-group {
  width: 100%;
}
</style>
