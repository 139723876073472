<template>
  <div>
    <RCSelect
      v-model="localValue"
      :value="value"
      :suggestions="suggestions"
      :floatLabel="floatLabel"
      :label="label"
      :requiredField="requiredField"
      @change="onChange"
      @getFullOption="$emit('getFullOption')"
    />
  </div>
</template>

<script>
import RCSelect from "rcat-components/RCSelect/RCSelect.vue";

export default {
  name: "CustomRCSelect",
  components: {
    RCSelect,
  },
  props: {
    // This props handles the v-model directive
    value: {
      required: false,
    },
    suggestions: {
      type: Array,
      default: () => [],
    },
    floatLabel: {
      type: Boolean,
      default: false,
    },
    label: String,
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.value || "",
    };
  },
  watch: {
    localValue: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    onChange(value) {
      this.localValue = value;
      this.$emit("input", value);
      this.$emit("change", value);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .rc-select-container {
    width: 100%;
    border: 1px solid $color-border-container;
  }
  .rc-select__selected-option,
  .rc-suggestions-list {
    color: $color-primary-company;
  }
}
</style>
